




































import { Component, Vue } from 'vue-property-decorator';

import Menu from '../../components/Menu/Menu.vue';
import Background from '../../components/Background/Background.vue';
import Tank from '@/assets/icons/war/Tank.vue';
import Helmet from '@/assets/icons/war/Helmet.vue';

import { NAME_ROUTES_CONQUISTAE } from '@/router/routes/War';

@Component({
  components: {
    Menu,
    Background,
    Tank,
    Helmet
  }
})
export default class Headquarters extends Vue {
  listMenu = [];

  isTabActive(tab: string): boolean {
    if (tab === 'war-room') {
      return this.$route.name === NAME_ROUTES_CONQUISTAE.WAR_ROOM;
    }
    if (tab === 'training-center') {
      return this.$route.name === NAME_ROUTES_CONQUISTAE.TRAINING_CENTER;
    }
    return false;
  }

  navigateToTab(tab: string): void {
    if (tab === 'war-room') {
      this.$router.push({ name: NAME_ROUTES_CONQUISTAE.WAR_ROOM });
    } else if (tab === 'training-center') {
      this.$router.push({ name: NAME_ROUTES_CONQUISTAE.TRAINING_CENTER });
    }
  }
}
